import { MenuItem, Button as ChakraButton } from '@chakra-ui/react';
import type { FC } from 'react';
import type { MouseEvent } from 'react';

interface ButtonProps {
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    menuButton?: boolean;
}

const Button: FC<ButtonProps> = (props) => {
    const { menuButton } = props;
    if (!menuButton) {
        return (
            <ChakraButton
                width="100%"
                as={ChakraButton}
                onClick={props.onClick}
                className="c-nav__btn"
                title={'Logout'}
                padding={'0px'}
            >
                Logout
            </ChakraButton>
        );
    } else {
        return (
            <MenuItem
                variant={'primary'}
                _focus={{
                    bg: 'primary',
                    color: 'secondary'
                }}
                _hover={{
                    bg: 'tertiary',
                    color: 'secondary'
                }}
                bg="primary"
                color="secondary"
                width="100%"
                as={ChakraButton}
                onClick={props.onClick}
                className="c-nav__btn"
                title={'Logout'}
                padding={'0px'}
            >
                Logout
            </MenuItem>
        );
    }
};

export default Button;
