import type { ChangeEvent } from 'react';
import type { MicrosoftProfile } from '~/strategies';
import { Box, Link } from '@chakra-ui/react';
import type { LoaderFunction, LinksFunction } from 'remix';
import { useLocation, useLoaderData, Link as RemixLink } from 'remix';
import { Outlet, useSubmit } from 'remix';
import { authenticator } from '~/server/auth.server';
import Footer, { links as footerLinks } from '~/components/footer/Footer';
import { NavBar, links as navLinks } from '@components/nav/Nav';
import type { MouseEvent } from 'react';
import type { FC } from 'react';
import { getColorScheme } from '~/cookie';
import FormToggle from '~/components/form/FormToggle';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import Logo from '~/components/logo/Logo';
import Button from '~/components/button/Button';

export const loader: LoaderFunction = async ({ request }) => {
    const user = (await authenticator().isAuthenticated(request, {
        failureRedirect: '/'
    })) as MicrosoftProfile;
    const colorScheme = await getColorScheme(request);
    return { colorScheme, user: user._json };
};

export const links: LinksFunction = () => {
    return [...navLinks(), ...footerLinks()];
};

export const logoChildren = () => {
    return (
        <Link prefetch={'intent'} color={'secondary'} as={RemixLink} to="/requests">
            <Logo className={'c-logo'} />
        </Link>
    );
};

export const NavChildren: FC = () => {
    const location = useLocation();
    const submit = useSubmit();
    const props = useLoaderData() ?? undefined;
    const colorScheme = props ? props.colorScheme : undefined;
    return (
        <>
            <Link as={RemixLink} color={'secondary'} prefetch={'intent'} to="/requests">
                Your Requests
            </Link>
            <Link color={'secondary'} as={RemixLink} prefetch={'intent'} to="/requests/submit">
                Submit a Request{' '}
            </Link>
            {colorScheme && (
                <FormToggle
                    leftIcon={<MoonIcon color={'secondary'} margin={2} />}
                    rightIcon={<SunIcon color={'secondary'} margin={2} />}
                    isChecked={colorScheme === 'light' ? true : false}
                    onToggle={(e: ChangeEvent<HTMLInputElement>) => {
                        const formData = new FormData();
                        formData.set('colorScheme', e.target.value);
                        formData.set('pathname', location.pathname);
                        submit(formData, { method: 'post', action: '/actions/changeTheme' });
                    }}
                    id="color-mode-toggle"
                />
            )}
        </>
    );
};

export default function Requests() {
    const submit = useSubmit();
    const avatarUrl = undefined;

    const onClick = (e: MouseEvent<HTMLButtonElement>) => {
        submit(e.currentTarget);
    };

    return (
        <Box bg={'primary'} className="o-row">
            <Box bg={'primary'} className="o-row__container">
                <NavBar
                    logoChildren={logoChildren}
                    navChildren={NavChildren}
                    avatarUrl={typeof avatarUrl === 'string' ? avatarUrl : undefined}
                    prefetch={false}
                >
                    {/* logout menuitem, use submit can't be used inside a story */}
                    <Button menuButton={true} onClick={onClick}></Button>
                </NavBar>
                <main style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <Outlet />
                </main>
            </Box>
            <Footer />
        </Box>
    );
}
