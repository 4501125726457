import type { FC } from 'react';
import { Box } from '@chakra-ui/react';
import styles from './avatar.css';
import type { LinksFunction } from 'remix';

export interface Props {
    avatarUrl?: string;
    className?: string;
}

export const links: LinksFunction = () => {
    return [{ rel: 'stylesheet', href: styles }];
};

const Avatar: FC<Props> = (props) => {
    const { avatarUrl, className } = props;
    return (
        <Box color={'secondary'}>
            {avatarUrl && (
                // todo build this
                <></>
            )}
            {!avatarUrl && (
                <svg
                    className={className ? className : 'c-avatar'}
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 200.688 200.688"
                    xmlSpace="preserve"
                >
                    <path
                        className={`${className ? className : 'c-avatar'}__path`}
                        d="M190.985,111.644l-0.293-0.347c-1.399-1.729-4.459-4.187-8.922-5.891l-0.684-0.208
       c-4.649-2.144-8.861-3.582-10.096-3.987c-0.97-0.319-1.782-0.666-2.43-0.948c-5.128-2.53-5.708-4.008-5.791-4.237l-0.233-0.544
       l0.197-0.49c3.811-4.746,6.488-10.275,7.508-15.486l0.15-0.354c1.052-1.342,1.725-2.91,1.993-4.638
       c1.213-4.384,1.263-7.605,0.175-9.863l-0.097-0.2l0.05-0.222c1.353-5.665,2.656-16.277-3.847-23.681
       c-1.038-1.303-5.025-5.705-12.759-7.97l-3.772-1.292c-6.181-1.904-10.089-2.362-10.74-2.373c-0.412,0-0.78,0.05-1.317,0.168
       l-0.465,0.093c-0.272-0.043-0.555-0.068-0.873-0.068c-2.069,0-4.305,0.909-4.305,0.909c-2.18,0.916-13.252,6.023-16.763,16.874
       c-0.583,1.542-1.772,6.231-0.233,16.488l0.039,0.254l-0.161,0.215c-1.578,2.126-1.711,5.572-0.397,10.203
       c0.344,2.051,1.013,3.69,1.997,4.924l0.165,0.347c0.97,5.372,3.357,10.665,6.904,15.317l0.283,0.376l-0.265,0.376
       c-0.154,0.229-0.261,0.465-0.301,0.637c-0.544,1.553-4.706,4.012-11.159,6.596c-1.442-0.523-2.444-0.852-2.799-0.97
       c-1.306-0.437-2.398-0.905-3.282-1.292c-6.825-3.368-7.723-5.332-7.859-5.783l-0.358-0.798l0.315-0.741
       c5.121-6.381,8.715-13.811,10.096-20.843l0.211-0.483c1.417-1.8,2.323-3.905,2.663-6.199c1.628-5.866,1.714-10.182,0.247-13.192
       l-0.161-0.319l0.086-0.344c1.822-7.609,3.568-21.845-5.161-31.762c-1.385-1.736-6.703-7.641-17.096-10.683l-5.078-1.743
       C77.89,4.865,72.629,4.296,71.946,4.289c-0.558,0-1.041,0.061-1.746,0.229l-0.68,0.111c-2.813-0.455-6.27,0.88-6.896,1.124
       c-2.928,1.228-17.769,8.092-22.475,22.643c-0.784,2.061-2.373,8.346-0.308,22.114l0.061,0.415l-0.258,0.344
       c-2.104,2.813-2.265,7.412-0.501,13.621c0.451,2.734,1.346,4.917,2.673,6.589l0.233,0.49c1.285,7.208,4.491,14.326,9.266,20.593
       l0.447,0.583l-0.426,0.616c-0.208,0.304-0.347,0.616-0.379,0.812c-1.185,3.375-14.394,9.276-25.968,12.809
       c-8.332,3.157-11.989,8.271-12.011,8.303C1.503,132.72,0.05,169.292,0,170.863c0.179,9.513,4.499,11.445,5.329,11.724l0.73,0.319
       c25.385,11.077,62.462,13.235,66.61,13.446l1.296,0.039c1.238,0,2.494-0.068,3.672-0.132l0.104-0.004l0.383,0.029
       c0.308,0.075,0.558,0.115,0.816,0.115h0.004l0.175-0.007c1.678-0.086,41.486-2.348,66.327-13.496
       c1.356-0.358,5.737-2.24,6.131-11.488c8.858-0.766,30.048-3.207,44.439-9.659c1.066-0.279,4.556-1.761,4.674-9.273
       C200.527,149.587,199.142,123.776,190.985,111.644z M55.104,98.377l0.691-0.644l0.687,0.644c6.077,5.726,12.809,8.872,18.936,8.872
       c6.438,0,13.084-2.792,19.218-8.081l0.519-0.437L96.5,99.39c1.145,1.041,3.196,2.537,4.116,2.988l1.267,0.619l-0.136,0.129
       l0.487,0.293c1.16,0.684,2.394,1.353,3.804,2.054c1.428,0.633,2.652,1.109,3.951,1.539c0.254,0.086,6.317,2.044,13.227,5.243
       l1.235,0.383c6.567,2.502,9.344,6.027,9.445,6.148c10.193,15.131,11.907,48.157,12.075,51.85c-0.079,5.161-1.55,6.499-1.933,6.757
       c-22.844,10.225-57.355,12.884-64.033,13.31l-0.186,0.011l-0.19-0.057c-0.225-0.068-0.455-0.1-0.741-0.1h-0.007l-0.236,0.011
       c-1.825,0.125-3.335,0.186-4.746,0.186h-1.106c-4.159-0.251-41.372-2.688-65.189-13.285c-0.48-0.2-1.886-1.825-1.99-6.571
       c0.004-0.354,1.228-36.003,11.925-51.893c0.523-0.659,3.432-4.048,9.244-6.267c5.107-1.571,17.751-5.762,24.665-10.769
       c0.29-0.175,0.576-0.469,0.884-0.784C52.838,100.639,53.89,99.508,55.104,98.377z M126.273,107.299l-0.945-0.276
       c-1.553-0.716-3.182-1.424-4.867-2.115c2.38-1.113,4.327-2.205,5.773-3.26c0.233-0.136,0.465-0.372,0.684-0.601
       c0.523-0.562,1.228-1.317,2.051-2.086l0.44-0.404l0.422,0.412c4.549,4.273,9.573,6.617,14.144,6.617
       c4.817,0,9.781-2.083,14.351-6.027l0.326-0.276l0.923,0.447c0.841,0.784,2.373,1.886,3.056,2.226l0.798,0.379l-0.097,0.097
       l0.501,0.293c0.87,0.519,1.8,1.013,2.842,1.528c1.07,0.472,1.979,0.83,2.942,1.152c0.186,0.061,4.656,1.496,9.824,3.89l0.909,0.279
       c4.706,1.8,6.753,4.27,6.95,4.527c7.605,11.273,8.833,35.71,8.944,38.433c-0.054,3.779-1.102,4.746-1.364,4.917
       c-14.308,6.403-34.851,8.808-43.49,9.566c-0.777-11.216-3.547-37.632-12.787-51.371l-0.39-0.469
       C136.34,112.868,132.239,109.583,126.273,107.299z M120.922,66.479l0.161-0.19c0.723-0.53,1.077-1.349,0.941-2.197
       c-1.718-10.318-0.601-14.648-0.218-15.74c2.996-9.187,12.419-13.474,14.272-14.233c0.372-0.143,1.092-0.358,1.854-0.487
       l0.229-0.054l1.421-0.075l0.011,0.097l0.429-0.039c0.319-0.025,0.626-0.075,0.841-0.122l0.48-0.107
       c0.304,0.007,4.026,0.49,9.42,2.144l3.804,1.306c6.907,2.04,10.107,5.851,10.697,6.621c5.551,6.299,4.062,15.776,2.677,20.868
       c-0.157,0.626-0.061,1.27,0.297,1.797l0.308,0.397c0.39,0.523,0.744,2.602-0.458,7.083c-0.225,1.36-0.73,2.452-1.453,3.178
       c-0.293,0.308-0.49,0.709-0.562,1.167c-1.872,10.998-11.721,23.291-22.096,23.291c-8.811,0-18.857-11.316-20.664-23.291
       c-0.075-0.455-0.268-0.855-0.587-1.217c-0.737-0.762-1.213-1.875-1.507-3.554C120.35,70.008,120.253,67.492,120.922,66.479z
        M44.249,54.626l0.24-0.276c0.931-0.669,1.381-1.721,1.21-2.809c-2.33-13.986-0.809-19.805-0.286-21.266
       C49.46,17.853,62.215,12.045,64.72,11.014c0.523-0.2,1.496-0.494,2.527-0.662l0.276-0.064l2.101-0.115l0.007,0.132l0.451-0.043
       c0.419-0.039,0.83-0.104,1.31-0.2l0.48-0.111c0.39,0.004,5.315,0.623,12.741,2.899l5.107,1.757
       c9.334,2.759,13.682,7.924,14.48,8.958c7.508,8.521,5.504,21.344,3.643,28.234c-0.218,0.795-0.086,1.621,0.369,2.294l0.415,0.533
       c0.684,0.916,0.941,3.983-0.587,9.663c-0.308,1.854-0.998,3.357-2.008,4.355c-0.369,0.394-0.608,0.909-0.712,1.492
       c-2.541,14.87-15.869,31.497-29.905,31.497c-11.928,0-25.528-15.303-27.983-31.487c-0.082-0.583-0.322-1.109-0.744-1.568
       c-1.024-1.056-1.671-2.577-2.069-4.839C43.437,59.479,43.315,56.054,44.249,54.626z"
                    />
                </svg>
            )}
        </Box>
    );
};

export default Avatar;
