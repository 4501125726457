import type { FC, ReactNode } from 'react';
import React from 'react';
import { Box, Flex, Stack, Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { links as logoLinks } from '../logo/Logo';
import type { LinksFunction } from 'remix';
import Avatar, { links as avatarLinks } from '../avatar/Avatar';
import styles from './nav.css';

export interface Props {
    avatarUrl?: string;
    prefetch: boolean;
    navChildren?: FC;
    logoChildren?: FC;
    hideLogoutButton?: boolean;
}

export const links: LinksFunction = () => {
    return [{ rel: 'stylesheet', href: styles }, ...logoLinks(), ...avatarLinks()];
};

export const NavBar: FC<Props> = (props) => {
    const { avatarUrl, prefetch, navChildren, logoChildren, hideLogoutButton, ...other } = props;

    const [isOpen, setIsOpen] = React.useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <NavBarContainer
            hideLogoutButton={hideLogoutButton}
            logoChildren={logoChildren}
            navChildren={navChildren}
            {...other}
        >
            {logoChildren && logoChildren(props)}
            <MenuToggle toggle={toggle} isOpen={isOpen} />
            <MenuLinks prefetch={prefetch} avatarUrl={avatarUrl} isOpen={isOpen}>
                {props.children}
            </MenuLinks>
        </NavBarContainer>
    );
};

interface MenuToggleProps {
    isOpen: boolean;
    toggle: () => void;
}

const MenuToggle: FC<MenuToggleProps> = (props: MenuToggleProps) => {
    const { toggle, isOpen } = props;

    return (
        <Box display={{ base: 'block', md: 'none' }} onClick={toggle}>
            {isOpen ? <CloseIcon color={'secondary'} /> : <HamburgerIcon color={'secondary'} />}
        </Box>
    );
};

interface MenuLinksProps {
    isOpen: boolean;
    avatarUrl?: string;
    prefetch: boolean;
    children: ReactNode;
    navChildren?: FC;
    hideLogoutButton?: boolean;
}

const MenuLinks: FC<MenuLinksProps> = (props) => {
    const { isOpen, avatarUrl, navChildren, hideLogoutButton } = props;

    return (
        <Box
            display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
            flexBasis={{ base: '100%', md: 'auto' }}
        >
            <Stack
                zIndex={2}
                style={{ fontWeight: 'bold' }}
                spacing={8}
                align="center"
                justify={['center', 'space-between', 'flex-end', 'flex-end']}
                direction={['column', 'row', 'row', 'row']}
                pt={[4, 4, 0, 0]}
            >
                {navChildren && navChildren({})}
                {!hideLogoutButton && (
                    <form action="/logout" method="post">
                        <Menu>
                            <MenuButton
                                key={'avatar-button'}
                                border={'none'}
                                className="c-nav__btn--blank"
                                as={Button}
                            >
                                <Avatar avatarUrl={avatarUrl} />
                            </MenuButton>
                            <MenuList className="c-nav__menu-list">{props.children}</MenuList>
                        </Menu>
                    </form>
                )}
            </Stack>
        </Box>
    );
};

interface NavBarProps {
    children: ReactNode;
    navChildren?: FC;
    logoChildren?: FC;
    hideLogoutButton?: boolean;
}

const NavBarContainer: FC<NavBarProps> = (props) => {
    const { children, navChildren, logoChildren, hideLogoutButton, ...other } = props;

    const childrenWithProps = React.Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child) && typeof child.type === 'function') {
            return React.cloneElement(child, { navChildren, logoChildren, hideLogoutButton });
        }
        return child;
    });

    return (
        <Flex
            border={'1px solid black'}
            zIndex={1}
            {...other}
            bg={'primary'}
            className={'c-nav'}
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            bottom="0px"
            p={8}
            contrast="50%"
        >
            {childrenWithProps}
        </Flex>
    );
};
