import type { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import styles from './footer.css';
import type { LinksFunction } from 'remix';

export interface Props {
    [x: string]: string | number;
}

export const links: LinksFunction = () => {
    return [{ rel: 'stylesheet', href: styles }];
};

const Footer: FC<Props> = () => {
    return (
        <Flex
            bg={'primary'}
            color={'secondary'}
            className={'o-footer'}
            as="footer"
            style={{
                textAlign: 'center',
                justifyContent: 'space-between',
                bottom: '0'
            }}
            w="100%"
            mb={0}
            p={8}
        ></Flex>
    );
};

export default Footer;
